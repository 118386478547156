.container {
  width: 100%;
  height: 90px;
  top: 0;
  left: 0;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  /* position: fixed; */
  z-index: 10;
}
.mainImg {
  width: 7rem;
}
.navbar {
  max-width: 1240px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.navbar ul {
  display: flex;
  list-style: none;
  align-items: center;
}
.navbar ul li {
  padding: 1rem;
  cursor: pointer;
  /* color: #919191; */
}
.navbar ul span {
  padding: 1rem;
  color: #919191;
  font-size: 12px;
}

.hamburger {
  display: none;
}

.navbar .icon {
  font-size: 1.5rem;
}

/* jfd;lsk */

@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
  }
  .mainImg {
    width: 6rem;
  }
  .navbar ul {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    transition: 0.4s ease-in-out;
    z-index: 10;
    top: 90px;
    background-color: white;
    padding: 3rem 0rem;
  }
  .navbar ul span {
    display: none;
  }
}
