.container {
  position: relative;
  margin: 3rem 0rem;
  max-width: 1240px;
  margin: auto;
  padding: 1rem;
  text-align: justify;
  padding-bottom: 5rem;
}
.container .title {
  text-align: center;
  margin: 1rem 0;
}
.container .images {
  --gap: 1.2rem;
  --num-cols: 6;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  grid-auto-rows: var(--row-height);
  gap: var(--gap);
}
.column2 {
  grid-column: span 2;
  grid-row: span 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.details {
  display: flex;
  justify-content: flex-start;
}
.key {
  color: #919191;
  padding: 3px 0rem;
  width: 12rem;
}
.value {
  text-align: start;
  padding: 3px 0rem;
}
.info {
  padding-bottom: 0.5rem;
  grid-column: span 3;
  grid-row: span 2;
}
.description {
  padding: 0rem 0rem 1rem 0rem;
  grid-column: span 3;
  grid-row: span 2;
}
.mainImg {
  grid-column: span 6;
  max-width: 100%;
  height: auto;
}
.singleImg {
  height: 75vh;
}
@media screen and (max-width: 768px) {
  .container .images {
    display: flex;
    flex-direction: column;
    --num-cols: 3;
  }
  .top {
    display: flex;
  }
  .column2 {
    grid-column: span 1;
    display: flex;
    flex-direction: row;
  }
  .info {
    padding-bottom: 0rem;
  }
  .description {
    padding-top: 0rem;
  }
  .singleImg {
    height: 30vh;
  }
}
