.container {
  position: relative;
  margin: 3rem 0rem;
  max-width: 1240px;
  margin: auto;
  padding-bottom: 5rem;
}
.container .title {
  text-align: center;
  margin: 1rem 0;
}
.container .images {
  --gap: 1.2rem;
  --num-cols: 5;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  grid-auto-rows: var(--row-height);
  gap: var(--gap);
}
.column2 {
  grid-column: span 2;
  grid-row: span 2;
}
.description {
  padding: 1rem;
  margin-bottom: 3rem;
  text-align: justify;
  --gap: 1.2rem;
  --num-cols: 1;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  grid-auto-rows: var(--row-height);
  gap: var(--gap);
}
.goal {
  font-size: 20px;
  padding: 0.5rem 0rem;
}
.desc1 {
  grid-column: span 3;
  grid-row: span 2;
}
.desc2 {
  grid-column: span 2;
  grid-row: span 2;
}
.desc3 {
  grid-column: span 3;
  grid-row: span 2;
}

@media screen and (max-width: 768px) {
  .container .images {
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
  .container .description {
    --num-cols: 1;
  }
}
