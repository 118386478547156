.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}
.container {
  width: 100%;
  padding: 2rem 0;
  background-color: #292e34;
  /* color: #6a6d71; */
  color: white;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container .info {
  display: flex;
  margin-bottom: 10px;
}
.container .info span {
  margin: 0rem 1rem;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .container .info {
    flex-direction: column;
  }
  .container .info span {
    display: none;
  }
  .container .info .phone {
    margin-top: 12px;
  }
}
