.container {
  position: relative;
  margin: auto;
  max-width: 1240px;
  padding-bottom: 5rem;
}
.container .title {
  text-align: center;
  margin: 1rem 0;
}
.filter {
  display: flex;
  padding: 1rem 1rem 0rem 1rem;
  align-items: center;
  flex-wrap: wrap;
}
.filter div {
  /* color: #919191; */
  color: black;
  cursor: pointer;
}
.span {
  margin: 0rem 1rem;
  color: #919191;
  font-size: 12px;
}
.projects {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.2rem;
  margin-bottom: 3rem;
  padding: 1rem;
}
.project {
  display: flex;
  flex-direction: column;
  max-width: 288px;
  font-size: 0.875rem;
}
.description {
  padding: 0.5rem 0rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #919191;
  display: flex;
  align-items: center;
}
.info span {
  margin: 0rem 0.5rem;
  font-size: 0.75rem;
}
@media screen and (max-width: 768px) {
  .container {
    max-width: 100%;
  }
  .filter {
    padding: 1rem;
    padding-bottom: 0rem;
  }
  .projects {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
    padding: 0.5rem;
  }
  .project {
    max-width: 11.5rem;
    padding: 0.5rem 0rem;
  }
}
