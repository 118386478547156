@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Oswald", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-height: 100vh;
  line-height: 1.5;
}

.container {
  max-width: 1240px;
  margin: auto;
}

@media print {
  html,
  body {
    display: none; /* hide whole page */
  }
}
