img {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.caption {
  cursor: pointer;
}

.effect {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.effect img {
  position: relative;
}

/*Left & Right Side*/
.effect .topSide,
.effect .bottomSide {
  position: absolute;
  left: 0;
  top: -125%;
  height: 50%;
  width: 100%;
  background: black;
  opacity: 0.3;
  transition: top 0.7s;
}

.effect .bottomSide {
  top: 125%;
  background: black;
}

.effect:hover .topSide,
.effect:active .topSide {
  top: 0;
}

.effect:hover .bottomSide,
.effect:active .bottomSide {
  top: 50%;
}

/* Text */
.effect .title,
.effect .description {
  opacity: 0;
  left: 5%;
  position: absolute;
  color: white;
  transition: opacity 0.6s, top 0.6s;
  text-align: left;
}
.effect .title {
  text-transform: uppercase;
  border-top: 1px solid white;
  margin-top: 0px;
  font-size: 0.8rem;
}

.effect:hover .title,
.effect:active .title {
  opacity: 1;
  top: 1rem;
}

.effect:hover .description,
.effect:active .description {
  opacity: 1;
  bottom: 1rem;
}
.effect .upperLine {
  width: 5%;
  height: 1px;
  color: white;
  position: absolute;
  top: 2%;
  left: 5%;
}
