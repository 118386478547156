.container {
  position: relative;
  margin: auto;
  max-width: 1240px;
  text-align: justify;
  padding-bottom: 5rem;
}
.container .title {
  text-align: center;
  margin: 1rem 0;
}
.container .images {
  --gap: 1.2rem;
  --num-cols: 6;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  grid-auto-rows: var(--row-height);
  gap: var(--gap);
}
.column2 {
  grid-column: span 3;
  grid-row: span 2;
  display: flex;
  flex-direction: column;
}
.description {
  padding: 1rem;
}
.images .column2 h4 {
  padding: 1rem 0rem;
}
@media screen and (max-width: 768px) {
  .container .images {
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
}
